import ItemCard from "./ItemCard";
import ItemCardSkeleton from "./ItemCardSketon";
import { CatalogItemNoImg } from "../../types";

const EightDisplay = (props: { itemList: CatalogItemNoImg[] }) => {
  return (
    <ul role="list">
      <li className="eightDisplayShowHide grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8">
        {props?.itemList?.length === 0 ? (
          <>
            {[...Array(8).keys()].map((val, index) => (
              <ItemCardSkeleton key={index} catalogTypeSlug={"catalogo"} />
            ))}
          </>
        ) : (
          <>
            {props.itemList.map((item) => (
              <ItemCard
                key={item.slug}
                item={item}
                catalogTypeSlug={item.catalog_type?.slug}
              />
            ))}
          </>
        )}
      </li>
    </ul>
  );
};

export default EightDisplay;
