import type { catalogs } from "@prisma/client";

export default function CarouselCard(props: { collection: catalogs }) {
  const cdn_url = process.env.NEXT_PUBLIC_cdn_url;
  if (!props.collection.url || !props.collection.title_es)
    throw Error("Bad collection data");
  return (
    <a href={props.collection.url}>
      <div className="relative">
        <div className="flex h-72 w-full overflow-hidden rounded-3xl">
          <picture className="flex items-center w-full">
            <source
              type="image/webp"
              srcSet={`${cdn_url}${props.collection.image_handle}-thumbnail.webp`}
            />
            <source
              type="image/jpeg"
              srcSet={`${cdn_url}${props.collection.image_handle}-thumbnail.jpg`}
            />
            <img
              src={`${cdn_url}${props.collection.image_handle}-thumbnail.jpg`}
              className="flex w-full rounded-3xl m-auto"
              alt={props.collection.title_es}
              loading="lazy"
            />
          </picture>
        </div>
        <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-3xl p-4">
          <div
            aria-hidden="true"
            className="absolute h-full inset-x-0 bottom-0 bg-gradient-to-t from-black opacity-50 transition-opacity hover:opacity-60 rounded-3xl"
          />
          <p className="relative text-lg font-semibold text-white">
            {props.collection.title_es}
          </p>
        </div>
      </div>
    </a>
  );
}
