"use client";
import { useEffect, useState } from "react";
import {
  FetchRandomCoins,
  FetchRandomBanknotes,
} from "../../../utils/clientFetch";
import EightDisplay from "../product/EightDisplay";

const HomeRandomItems = () => {
  const [itemListCoins, setItemsCoins] = useState([]);
  const [itemListBanknotes, setItemsBanknotes] = useState([]);

  useEffect(() => {
    (async () => {
      setItemsCoins([]);
      setItemsBanknotes([]);
      const response = await FetchRandomCoins();
      setItemsCoins(response);
      const responseBanknotes = await FetchRandomBanknotes();
      setItemsBanknotes(responseBanknotes);
    })();
  }, []);

  return (
    <>
      <div className="text-gray-700 mx-auto px-4 px-6 mt-16 sm:mt-24 lg:mt-32 mb-16">
        <div className="flex flex-col lg:flex-row gap-x-6 container mx-auto px-4 sm:px-6 lg:px-8 mt-8">
          <div className="flex-auto">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <h1 className="mt-2 font-semibold text-gray-900 mb-2">
                Ver monedas al azar:
              </h1>
              <EightDisplay itemList={itemListCoins} />
            </div>
            <div className="mx-auto lg:mt-12 max-w-7xl sm:px-6 lg:px-8">
              <h1 className="mt-2 font-semibold text-gray-900 mb-2">
                Ver billetes al azar:
              </h1>
              <EightDisplay itemList={itemListBanknotes} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeRandomItems;
