import { CoinSVG, BankNoteSVG } from "./NoImagePlaceholder";

const ItemCardSkeleton = (props: { catalogTypeSlug: string }) => {
  return (
    <div className="rounded-lg bg-white text-center shadow">
      <div className="col-span-1 flex flex-col divide-y divide-gray-200">
        <div className="flex flex-1 flex-col xl:px-6 lg:px-6 p-3 animate animate-pulse">
          <div className="flex flex-col lg:flex-row mx-auto">
            {props.catalogTypeSlug === "catalogo" ? (
              <>
                <CoinSVG />
                <CoinSVG />
              </>
            ) : (
              <>
                <BankNoteSVG />
                <BankNoteSVG />
              </>
            )}
          </div>
          <h3 className="mt-6 text-sm font-medium text-gray-900"></h3>
          <dl className="mt-1 flex flex-grow flex-col justify-between">
            <dt className="sr-only">Title</dt>
            <dd className="text-sm text-gray-500 text-center">
              <div className="h-2 bg-gray-500 rounded-full"></div>
            </dd>
            <dt className="sr-only">Divisa y año</dt>
            <dd className="mt-5">
              <div className="h-2 bg-gray-200 rounded-full"></div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
export default ItemCardSkeleton;
