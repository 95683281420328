"use client";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import CarouselCard from "./CarouselCard";

export const CollectionsCarousel = (props: { collections: any }) => {
  const autoplayOptions = {
    delay: 1500,
  };
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay(autoplayOptions),
  ]);

  return (
    <>
      <div className="embla max-w-full overflow-hidden" ref={emblaRef}>
        <div className="embla__container">
          {props.collections.map((collection: any) => (
            <div key={collection.id} className="embla__slide">
              <CarouselCard collection={collection} />
            </div>
          ))}
          {props.collections.map((collection: any) => (
            <div key={collection.id} className="embla__slide">
              <CarouselCard collection={collection} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
